const brand = {
  key: 'dyades',
  accessKey: process.env.REACT_APP_DYADES_ACCESS_KEY,
  name: 'Dyades',
  url: 'http://www.dyadesparis.com/',
  features: {
    withUidCheck: false,
    withReferenceInput: true,
    withIssueRequest: false,
    withTextRecognition: false,
  },
  images: {
    logo: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1692801922/certificate-request-page/brands/dyades/logo.png',
    homePage:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1692801945/certificate-request-page/brands/dyades/home-page.png',
    homePageCompressed: null,
    certificateExample: null,
    certificateExampleCompressed: null,
    favicon:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1690540652/certificate-request-page/brands/brand%20z/favicon.png',
    uidExample: null,
  },
};

export default brand;
