const fonts = [
  {
    '@font-face': {
      fontFamily: 'primary',
      src: `url('https://res.cloudinary.com/dnkj85yan/raw/upload/v1715084543/certificate-request-page/fonts/Gotham_Pro_Regular_lbbc2i.otf') format("truetype")`,
    },
  },

  {
    '@font-face': {
      fontFamily: 'secondary',
      src: `url('https://res.cloudinary.com/dnkj85yan/raw/upload/v1715084543/certificate-request-page/fonts/Gotham_Pro_Light_x8qmp2.otf') format("truetype")`,
    },
  },
];

export default fonts;
